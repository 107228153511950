import React from 'react'
import { Outlet } from 'react-router-dom'

function ProfileIndex() {
  return (
    <div>
      {/* 父组件需要加一个下面的组件进行子路由页面的分发 */}
      <Outlet />
    </div>
  )
}

export default ProfileIndex
