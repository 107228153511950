import React from 'react'

interface Props {
  color?: string
}

const Payment = ({ color = '#A6A9BB' }: Props) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 9C7 8.44772 7.44772 8 8 8H26.802C26.9113 8 27 8.08866 27 8.19802V10H8C7.44772 10 7 9.55228 7 9ZM7 12H28C28.5523 12 29 12.4477 29 13V27C29 27.5523 28.5523 28 28 28H8C7.44772 28 7 27.5523 7 27V12ZM25 22C26.1046 22 27 21.1046 27 20C27 18.8954 26.1046 18 25 18C23.8954 18 23 18.8954 23 20C23 21.1046 23.8954 22 25 22Z"
      fill={color}
    />
  </svg>
)

export default Payment
