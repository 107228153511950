import { Component } from 'react'
import * as Sentry from '@sentry/browser'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'production' && window.SENTRY_DSN && window.SENTRY_ORG) {
      Sentry.captureException(error)
    }

    console.error(errorInfo)
  }

  render() {
    const { hasError } = this.state

    if (hasError) {
      console.error('got error')
      return ''
    }

    const { children } = this.props

    return children
  }
}

export default ErrorBoundary
