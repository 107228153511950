import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

const Spin = () => (
  <div className="w-full h-screen flex flex-row justify-center items-center">
    <CircularProgress className="t-mui-loading" />
  </div>
)

export default Spin
