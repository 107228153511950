import React from 'react'

interface Props {
  height?: string | number
  width?: string | number
  color?: string
}

const MaintenanceIcon = ({ color = '#A6A9BB', width = 36, height = 36 }: Props) => (
  <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.3899 26.14C28.6475 26.3975 29.0835 26.3011 29.1863 25.9516C29.2453 25.7512 29.2973 25.5471 29.3184 25.3923C29.505 24.0202 29.071 22.5798 28.0162 21.525C26.6854 20.1942 24.7405 19.8515 23.0935 20.4971L15.8628 13.2664C16.5084 11.6193 16.1657 9.67449 14.8348 8.34363C13.7801 7.28889 12.3397 6.85483 10.9675 7.04148C10.8128 7.06253 10.6087 7.1146 10.4082 7.17356C10.0588 7.27637 9.96233 7.71234 10.2199 7.96995L12.7137 10.4647L12.2386 12.238L10.4653 12.7131L7.96995 10.2186C7.71249 9.96126 7.2767 10.0574 7.17367 10.4065C7.11622 10.6012 7.06562 10.7987 7.04458 10.9489C6.85167 12.3265 7.28489 13.7749 8.34425 14.8342C9.67494 16.1649 11.6195 16.5077 13.2664 15.8624L20.4974 23.0935C19.8522 24.7404 20.1949 26.6849 21.5256 28.0156C22.585 29.075 24.0334 29.5082 25.4109 29.3153C25.5612 29.2942 25.7586 29.2436 25.9533 29.1862C26.3025 29.0832 26.3986 28.6474 26.1412 28.3899L23.6467 25.8945L24.1219 24.1213L25.8951 23.6461L28.3899 26.14ZM24.1218 13.7368L21.5256 16.3329L20.2275 15.0348L22.8238 12.4386L23.3247 9.95796C23.4224 9.47433 23.7517 9.06966 24.2053 8.87568L27.7052 7.37922C27.8931 7.2989 28.1109 7.34095 28.2554 7.48541L29.075 8.30503C29.2195 8.44959 29.2615 8.66759 29.181 8.85551L27.6786 12.3619C27.4841 12.8158 27.0786 13.145 26.5943 13.2419L24.1218 13.7368L24.1218 13.7368ZM8.54445 28.0164C9.61984 29.0917 11.3634 29.0917 12.4388 28.0164L17.6313 22.8239L13.7369 18.9295L8.54445 24.122C7.46905 25.1974 7.46905 26.941 8.54445 28.0164Z"
      fill={color}
    />
  </svg>
)

export default MaintenanceIcon
