const getQueryVariable = () => {
  const query = window.location.search.substring(1)
  const querys = query.split('&')
  const values = {}
  if (querys) {
    querys.forEach(val => {
      const pair = val.split('=')
      if (pair && Array.isArray(pair) && pair.length > 1) {
        const [key, value] = pair
        values[key] = decodeURIComponent(value)
      }
    })
  }

  return values
}

export default getQueryVariable
