import { useEffect, Suspense, lazy } from 'react'
import { useRoutes, useLocation, Navigate } from 'react-router-dom'
import { startCase } from 'lodash-es'
import Layout from '../components/layout'

import PaymentIndex from '../components/payment/paymentIndex'
import MaintenIdx from '../components/maintenance/maintenIndex'
import ProfileIndex from '../components/profile/profileIndex'
// import Leasing from '../components/leasing'
import Spin from '../components/spin'
import Auth from './auth'
import getQueryVariable from '../utils/getQueryVariable'

const Overview = lazy(() => import('../components/overview'))
const Documents = lazy(() => import('../components/document'))
const Payment = lazy(() => import('../components/payment/payment'))
const PaymentAdd = lazy(() => import('../components/payment/addMethod'))
const Profile = lazy(() => import('../components/profile/index'))
const ProfileInfo = lazy(() => import('../components/profile/profileDetail'))
const ProfilePaymentMethods = lazy(() => import('../components/profile/paymentMethods'))
const Maintenance = lazy(() => import('../components/maintenance/maintenance'))
const MaintenanceRequest = lazy(() => import('../components/maintenance/request'))
const RequestSuccessed = lazy(() => import('../components/maintenance/requestSubmited'))
const OrderDetail = lazy(() => import('../components/maintenance/orderDetail'))
const Inspections = lazy(() => import('../components/inspections'))
const MoveInInspection = lazy(() => import('../components/inspections/moveInInspection'))
const CheckList = lazy(() => import('../components/inspections/checkList'))
const Signature = lazy(() => import('../components/inspections/signature'))
const SignatureList = lazy(() => import('../components/inspections/signatureList'))
const ContactUs = lazy(() => import('../components/contactUs'))
const ExploreList = lazy(() => import('../components/home/exploreList'))
const EatAndShop = lazy(() => import('../components/tips/eatAndShop'))
const Home = lazy(() => import('../components/home'))
const ToDoList = lazy(() => import('../components/home/toDoList'))
const MakeSuggestion = lazy(() => import('../components/contactUs/makeSuggestion'))
const Insurance = lazy(() => import('../components/insurance'))
const CheckInInformation = lazy(() => import('../components/checkInInformation'))
const EditCheckInInformation = lazy(() => import('../components/checkInInformation/editInformation'))
const InsuranceEdit = lazy(() => import('../components/insurance/insuranceEdit'))
const MoveInTips = lazy(() => import('../components/tips/moveInTips'))
const ExploreApartment = lazy(() => import('../components/tips/exploreApartment'))
const InfoDesk = lazy(() => import('../components/infoDesk'))
const PickUpKeys = lazy(() => import('../components/pickUpKeys'))
const Utility = lazy(() => import('../components/utility'))
const MoveInGuide = lazy(() => import('../components/tips/moveInGuide'))
const Login = lazy(() => import('../components/login'))
const LeaseSwitch = lazy(() => import('../components/leaseSwitch'))
const HistoryLedger = lazy(() => import('../components/payment/activitiesDetail'))
const Renew = lazy(() => import('../components/renew'))
const Feedback = lazy(() => import('../components/feedback'))

export default function AuthRoute() {
  const { pathname } = useLocation()
  const { token } = getQueryVariable()
  if (token) {
    localStorage.setItem('token', token)
  }

  const getHeaderTitle = () => {
    if (['maintenance', 'payment', 'application'].includes(pathname.split('/')[1])) {
      return pathname.split('/')[1]
    }
    return pathname.split('/').pop()
  }

  useEffect(() => {
    document.title = `Tripalink - ${startCase(getHeaderTitle())}`
  }, [pathname])


  if (pathname.includes('renew')) {
    return useRoutes([
      {
        path: '/renew',
        element: (
          <Suspense fallback={Spin()}>
            <Renew />
          </Suspense>
        ),
      },
    ])
  }

  if (pathname.includes('feedback')) {
    return useRoutes([
      {
        path: '/feedback',
        element: (
          <Suspense fallback={Spin()}>
            <Feedback />
          </Suspense>
        ),
      },
    ])
  }

  if (pathname.includes('leaseSwitch')) {
    return useRoutes([
      {
        path: '/leaseSwitch',
        element: (
          <Suspense fallback={Spin()}>
            <LeaseSwitch />
          </Suspense>
        ),
      },
    ])
  }

  const routs = [
    {
      path: '/login',
      element: (
        <Suspense fallback={Spin()}>
          <Login />
        </Suspense>
      ),
    },
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          index: true,
          path: 'overview',
          element: (
            <Suspense fallback={Spin()}>
              <Overview />
            </Suspense>
          ),
        },
        {
          path: 'maintenance',
          element: <MaintenIdx />,
          children: [
            {
              index: true,
              path: 'my-request',
              element: (
                <Suspense fallback={Spin()}>
                  <Maintenance />
                </Suspense>
              ),
            },
            {
              path: 'request/repair',
              element: (
                <Suspense fallback={Spin()}>
                  <MaintenanceRequest />
                </Suspense>
              ),
            },
            {
              path: 'request/successed',
              element: (
                <Suspense fallback={Spin()}>
                  <RequestSuccessed />
                </Suspense>
              ),
            },
            {
              path: 'detail/:workOrderId',
              element: (
                <Suspense fallback={Spin()}>
                  <OrderDetail />
                </Suspense>
              ),
            },
            {
              path: 'my-inspections',
              element: (
                <Suspense fallback={Spin()}>
                  <Inspections />
                </Suspense>
              ),
            },
            {
              path: 'my-inspections/move-in-inspections/:inspectionId',
              element: (
                <Suspense fallback={Spin()}>
                  <MoveInInspection />
                </Suspense>
              ),
            },
            {
              path: 'my-inspections/move-in-inspections/:inspectionId/:inspectionAreaId',
              element: (
                <Suspense fallback={Spin()}>
                  <CheckList />
                </Suspense>
              ),
            },
            {
              path: 'my-inspections/move-in-inspections/signature/:inspectionId',
              element: (
                <Suspense fallback={Spin()}>
                  <Signature />
                </Suspense>
              ),
            },
            {
              path: 'my-inspections/move-in-inspections/signaturelist/:inspectionId',
              element: (
                <Suspense fallback={Spin()}>
                  <SignatureList />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: 'document',
          element: (
            <Suspense fallback={Spin()}>
              <Documents />
            </Suspense>
          ),
        },
        {
          path: 'payment',
          element: <PaymentIndex />,
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={Spin()}>
                  <Payment />
                </Suspense>
              ),
            },
            {
              path: 'add-pay-method',
              element: (
                <Suspense fallback={Spin()}>
                  <PaymentAdd />
                </Suspense>
              ),
            },
            {
              path: 'activities-detail',
              element: (
                <Suspense fallback={Spin()}>
                  <HistoryLedger />
                </Suspense>
              ),
            },
          ],
        },
        // { path: 'leasing', element: <Leasing /> },
        {
          path: 'user-profile',
          element: <ProfileIndex />,
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={Spin()}>
                  <Profile />
                </Suspense>
              ),
            },
            {
              path: 'personal-infomation',
              element: (
                <Suspense fallback={Spin()}>
                  <ProfileInfo />
                </Suspense>
              ),
            },
            {
              path: 'payment-methods',
              element: (
                <Suspense fallback={Spin()}>
                  <ProfilePaymentMethods />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: 'home',
          element: <ProfileIndex />,
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={Spin()}>
                  <Home />
                </Suspense>
              ),
            },
            {
              path: 'to-do',
              element: (
                <Suspense fallback={Spin()}>
                  <ToDoList />
                </Suspense>
              ),
            },
            {
              path: 'all-explore',
              element: (
                <Suspense fallback={Spin()}>
                  <ExploreList />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: 'contact-us',
          element: <ProfileIndex />,
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={Spin()}>
                  <ContactUs />
                </Suspense>
              ),
            },
            {
              path: 'customer-service',
              element: (
                <Suspense fallback={Spin()}>
                  <MakeSuggestion />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: 'insurance',
          element: <ProfileIndex />,
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={Spin()}>
                  <Insurance />
                </Suspense>
              ),
            },
            {
              path: 'insurance',
              element: (
                <Suspense fallback={Spin()}>
                  <InsuranceEdit />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: 'check-in-information',
          element: <ProfileIndex />,
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={Spin()}>
                  <CheckInInformation />
                </Suspense>
              ),
            },
            {
              path: 'check-in-information',
              element: (
                <Suspense fallback={Spin()}>
                  <EditCheckInInformation />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: 'tripalink-move-in-tips',
          element: <ProfileIndex />,
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={Spin()}>
                  <MoveInTips />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: 'd1-eating-&-shopping',
          element: <ProfileIndex />,
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={Spin()}>
                  <EatAndShop />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: 'explore-apartment',
          element: <ProfileIndex />,
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={Spin()}>
                  <ExploreApartment />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: 'info-desk',
          element: <ProfileIndex />,
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={Spin()}>
                  <InfoDesk />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: 'pick-up-your-keys',
          element: <ProfileIndex />,
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={Spin()}>
                  <PickUpKeys />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: 'set-up-your-utility',
          element: <ProfileIndex />,
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={Spin()}>
                  <Utility />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: 'get-ready-to-move-a-guide',
          element: <ProfileIndex />,
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={Spin()}>
                  <MoveInGuide />
                </Suspense>
              ),
            },
          ],
        },
        { path: '*', element: <Navigate to="/overview" /> },
      ],
    },
  ]

  const routers = useRoutes(routs)

  return <Auth>{routers}</Auth>
}
