import { useState } from 'react'
import { useQuery } from '@apollo/client'
// import moment from 'moment'
import { OWN_LEASES } from '../../../graphqls/queries'
import { LeaseType } from '../utils/types.d'

const useOwnLease = (callback: (params: LeaseType) => void) => {
  const userId = localStorage.getItem('userId')
  const [lease, setLease] = useState({})

  useQuery(OWN_LEASES, {
    variables: {
      userId,
      num: 1,
      size: 20,
      createdAt: 'DESC',
      filterBy: {
        statusEqualTo: 'FULLY_EXECUTED',
      },
    },

    onCompleted: ({ ownLeases: { data = [] } }) => {
      if (callback) {
        callback(data)
      }

      setLease(data)
    },
    onError: error => {
      console.error(error.message)
    },
    errorPolicy: 'ignore',
  })

  return [lease]
}

export default useOwnLease
