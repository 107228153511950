import { useState } from 'react'
import { Box, Stack, IconButton, Button, Dialog, DialogTitle, DialogContent, Typography, useTheme, useMediaQuery } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import useOwnLease from './payment/hooks/useOwnLease'
import { Close } from '../assets/icons'

const LeaseDialog = ({ visible, onClose = () => {} }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('lg'))
  const [leaseId, setLeaseId] = useState('')
  const [leaseList, setLeaseList] = useState([])

  useOwnLease(data => {
    setLeaseList(data)
  })
  const onClick = idx => {
    setLeaseId(idx)
  }
  const onCloseDialog = () => {
    setLeaseId('')
    onClose()
  }
  const onSwitch = () => {
    localStorage.setItem('leaseId', leaseId)
    onClose()
    window.location.reload()
  }

  if (matches) {
    return (
      <Dialog
        open={visible}
        onClose={onClose}
        scroll="paper"
        sx={{
          '& .MuiPaper-root': {
            position: 'relative',
            width: '100%',
            maxWidth: '600px',
            overflowY: 'hidden',
            margin: '0',
            padding: '24px',
            borderRadius: '24px',
          },
        }}
      >
        <DialogTitle className="p-0 m-0 h-auto relative w-full">
          <Stack direction="row" justifyContent="space-between">
            <Typography className="font-averta text-primary leading-8 text-2xl font-semibold">Switch Unit/Room</Typography>
            <IconButton onClick={onClose}>
              <Close color="gray" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent className="p-0 m-0 bg-white rounded-3xl overflow-hidden">
          <Box className="max-h-[55vh] overflow-auto py-4 px-12">
            {leaseList.length &&
              leaseList.map(({ id, property: { name = '', aliasName = '' } = {}, bedroom = {}, unit = {} } = {}) => (
                <Stack
                  direction="column"
                  className={`w-full p-8 bg-white shadow-6xl rounded-3xl mb-4 border-2 border-solid border-white ${
                    leaseId === id ? ' border-black' : ''
                  } hover:border-gray-800`}
                  key={id}
                  onClick={() => onClick(id)}
                >
                  {aliasName || name ? (
                    <Typography className="font-averta text-primary leading-8 text-2xl font-semibold">{aliasName || name}</Typography>
                  ) : (
                    ''
                  )}
                  {bedroom?.address?.fullAddress || unit?.address?.fullAddress ? (
                    <Typography className="font-averta text-gray-700 leading-6 text-base font-normal mt-2.5">
                      {bedroom?.address?.fullAddress || unit?.address?.fullAddress}
                    </Typography>
                  ) : (
                    ''
                  )}
                </Stack>
              ))}
          </Box>
          <Stack direction="row" justifyContent="flex-end" spacing="8px" className="pr-2 pb-2">
            <Button
              className="w-[106px] h-12 rounded-lg font-averta text-primary capitalize border border-solid border-primary"
              onClick={onClose}
            >
              Cancel
            </Button>
            <LoadingButton
              fullWidth
              disabled={!leaseId}
              // loading={}
              disableElevation
              sx={{
                '&.MuiButton-root': {
                  backgroundColor: '#E74F4F',
                  '&.Mui-disabled': {
                    backgroundColor: '#e74f4f4d',
                  },
                },
              }}
              className="w-[106px] h-12 rounded-lg font-averta text-white capitalize"
              variant="contained"
              onClick={onSwitch}
            >
              Switch
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }
  return (
    <Dialog
      open={visible}
      onClose={onClose}
      scroll="paper"
      sx={{
        '& .MuiPaper-root': {
          position: 'relative',
          width: '90%',
          overflowY: 'hidden',
          margin: '0',
          padding: '16px',
          borderRadius: '24px',
        },
      }}
    >
      <DialogTitle className="p-0 m-0 h-auto relative w-full">
        <Stack direction="row" justifyContent="space-between">
          <Typography className="font-averta text-primary leading-8 text-xl font-semibold">Switch Unit/Room</Typography>
          <IconButton onClick={onCloseDialog}>
            <Close color="gray" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent className="p-0 m-0 bg-white rounded-3xl overflow-hidden">
        <Box className="max-h-[50vh] overflow-auto p-4 px-6">
          {leaseList.length
            ? leaseList.map(({ id, property: { name, aliasName = '' }, bedroom = {}, unit = {} }) => (
                <Stack
                  direction="column"
                  className={`w-full px-6 py-3 bg-white shadow-6xl rounded-3xl mb-4 border-2 border-solid border-white ${
                    leaseId === id ? 'border-black' : ''
                  } hover:border-gray-800`}
                  key={id}
                  onClick={() => onClick(id)}
                >
                  {aliasName || name ? (
                    <Typography className="font-averta text-primary leading-8 text-xl font-semibold">{aliasName || name}</Typography>
                  ) : (
                    ''
                  )}
                  {bedroom?.address?.fullAddress || unit?.address?.fullAddress ? (
                    <Typography className="font-averta text-gray-700 leading-6 text-sm font-normal">
                      {bedroom?.address?.fullAddress || unit?.address?.fullAddress}
                    </Typography>
                  ) : (
                    ''
                  )}
                </Stack>
              ))
            : ''}
        </Box>
        <Stack direction="row" justifyContent="flex-end" spacing="8px" className="pr-2 pb-2">
          <Button
            className="w-[106px] h-12 rounded-lg font-averta text-primary capitalize border border-solid border-primary"
            onClick={onCloseDialog}
          >
            Cancel
          </Button>
          <LoadingButton
            fullWidth
            disabled={!leaseId}
            // loading={}
            disableElevation
            sx={{
              '&.MuiButton-root': {
                backgroundColor: '#E74F4F',
                '&.Mui-disabled': {
                  backgroundColor: '#e74f4f4d',
                },
              },
            }}
            className="w-[106px] h-12 rounded-lg font-averta text-white capitalize"
            variant="contained"
            onClick={onSwitch}
          >
            Switch
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
export default LeaseDialog
