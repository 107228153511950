/**
 * 解析浏览器地址栏 url 的 search 的某个 name 的值
 * @param   {String}
 * @return  {String}
 */

export const queryURL = (name: string) => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r != null) return decodeURI(r[2])
  return null
}

/**
 * 数组内查询
 * @param   {array}     array
 * @param   {String}    id
 * @param   {String}    keyAlias
 * @return  {Array}
 */
export const queryArray = (array: [], key: string, keyAlias = 'key') => {
  if (!(array instanceof Array)) {
    return null
  }
  const item = array.filter(_ => _[keyAlias] === key)
  if (item.length) {
    return item[0]
  }
  return null
}

/**
 * 截取指定长度字符串
 * @author ronffy
 */

export default function sliceString(str: string, start: number, end: number) {
  const arr = str.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]|[^\uD800-\uDFFF]/g)
  if (!arr) {
    return ''
  }
  return arr.slice(start, end).join('')
}

/**
 * 获取headerTitle
 * @returns
 */
export const getHeaderTitle = () => {
  if (window.location.pathname.includes('activities-detail')) {
    return 'Account Activities'
  }
  if (window.location.pathname.includes('payment')) {
    return 'payment'
  }
  if (window.location.pathname.includes('move-in-inspections')) {
    return 'move-in-inspection'
  }
  if (['payment', 'inspcetion'].includes(window.location.pathname.split('/')[1])) {
    return window.location.pathname.split('/')[1]
  }
  return window.location.pathname.split('/').pop() ?? ''
}

/**
 *  header background image
 */
export const getHeaderBg = () => ['overview'].includes(window.location.pathname.split('/').pop() || '')

export const hasHeader = () => ['home'].includes(window.location.pathname.split('/').pop() || '')

export const scrollToTop = () => {
  const sTop = document.documentElement.scrollTop || document.body.scrollTop
  if (sTop > 0) {
    window.requestAnimationFrame(scrollToTop)
    window.scrollTo(0, sTop - sTop / 8)
  }
}

export const getOSType = () => {
  if (/(Android)/i.test(navigator.userAgent)) {
    return 'android'
  }
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    return 'ios'
  }
  return 'pc'
}
