import React from 'react'

interface Props {
  color?: string
}

const Overview = ({ color = '#A6A9BB' }: Props) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 7.5C7.94772 7.5 7.5 7.94772 7.5 8.5V16.0455C7.5 16.5977 7.94772 17.0455 8.5 17.0455H16.0455C16.5977 17.0455 17.0455 16.5977 17.0455 16.0455V8.5C17.0455 7.94772 16.5977 7.5 16.0455 7.5H8.5ZM19.9545 7.5C19.4023 7.5 18.9545 7.94772 18.9545 8.5V16.0455C18.9545 16.5977 19.4023 17.0455 19.9545 17.0455H27.5C28.0523 17.0455 28.5 16.5977 28.5 16.0455V8.5C28.5 7.94772 28.0523 7.5 27.5 7.5H19.9545ZM7.5 19.9545C7.5 19.4023 7.94772 18.9545 8.5 18.9545H16.0455C16.5977 18.9545 17.0455 19.4023 17.0455 19.9545V27.5C17.0455 28.0523 16.5977 28.5 16.0455 28.5H8.5C7.94772 28.5 7.5 28.0523 7.5 27.5V19.9545ZM19.9545 18.9545C19.4023 18.9545 18.9545 19.4023 18.9545 19.9545V27.5C18.9545 28.0523 19.4023 28.5 19.9545 28.5H27.5C28.0523 28.5 28.5 28.0523 28.5 27.5V19.9545C28.5 19.4023 28.0523 18.9545 27.5 18.9545H19.9545Z"
      fill={color}
    />
  </svg>
)

export default Overview
