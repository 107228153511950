import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web'
import { ZoneContextManager } from '@opentelemetry/context-zone'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { Resource } from '@opentelemetry/resources'
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base'
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions'

const IngestionSettings: Readonly<any> = Object.freeze({
  staging: {
    serviceName: 'frontend-tenant-portal-staging',
    ingestionKey: 'f2DJ3H-aFUGMhfbPpFeAou6nRmXeGQfPkoDA',
  },
})

export const initSigNoz = (env: string) => {
  const injestionSetting = IngestionSettings[env]
  if (env === 'staging') {
    const provider = new WebTracerProvider({
      resource: new Resource({
        [SemanticResourceAttributes.SERVICE_NAME]: injestionSetting.serviceName,
      }),
    })

    const exporter = new OTLPTraceExporter({
      url: 'https://ingest.us.signoz.cloud:443/v1/traces',
      headers: {
        'signoz-access-token': injestionSetting.ingestionKey,
      },
    })

    provider.addSpanProcessor(new BatchSpanProcessor(exporter))

    provider.register({
      // Changing default contextManager to use ZoneContextManager - supports asynchronous operations so that traces are not broken
      contextManager: new ZoneContextManager(),
    })

    // Registering instrumentations
    registerInstrumentations({
      instrumentations: [
        getWebAutoInstrumentations({
          '@opentelemetry/instrumentation-xml-http-request': {
            propagateTraceHeaderCorsUrls: [
              /.+/g, // Regex to match your backend URLs.
            ],
            ignoreUrls: [/algolia\.net/],
          },
          '@opentelemetry/instrumentation-fetch': {
            propagateTraceHeaderCorsUrls: [
              /.+/g, // Regex to match your backend URLs.
            ],
          },
        }),
      ],
    })
  }
}
