import { ReactNode } from 'react'
import { OverviewIcon, PaymentIcon, MaintenanceIcon, ProfileIcon } from '../assets/icons'

const [overview, overviews, payment, payments, maintenance, maintenances, profile, profiles] = [
  <OverviewIcon />,
  <OverviewIcon color="#E74F4F" />,
  <PaymentIcon />,
  <PaymentIcon color="#E74F4F" />,
  <MaintenanceIcon />,
  <MaintenanceIcon color="#E74F4F" />,
  <ProfileIcon />,
  <ProfileIcon color="#E74F4F" />,
]

type navItem = {
  path: string
  name: string
  meta: {
    title?: string
    icon?: ReactNode
    activeIcon?: ReactNode
  }
  children?: navItem[]
}

// #E74F4F
const defaultNavs: Array<navItem> = [
  {
    path: '/overview',
    name: 'Overview',
    meta: { title: 'Overview', icon: overview, activeIcon: overviews },
  },
  {
    path: '/payment',
    name: 'Payment',
    meta: { title: 'Payment', icon: payment, activeIcon: payments },
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    meta: { title: 'Maintenance', icon: maintenance, activeIcon: maintenances },
    children: [
      {
        path: '/maintenance/my-request',
        name: 'My Request',
        meta: { title: 'Maintenance' },
      },
      // {
      //   path: '/maintenance/my-inspections',
      //   name: 'My Inspections',
      //   meta: { title: 'Move-in Inspection' },
      // },
    ],
  },
  // {
  //   path: '/document',
  //   name: 'Sign Documents',
  //   meta: { title: 'sign document', icon: document, activeIcon: documents },
  // },
  {
    path: '/user-profile',
    name: 'Profile',
    meta: { title: 'User Profile', icon: profile, activeIcon: profiles },
  },
]
const getNavsLinkConfig = () => defaultNavs

export type { navItem }
export default getNavsLinkConfig
