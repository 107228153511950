interface Props {
  color?: string
}
const Logo = ({ color = '#151515' }: Props) => (
  <svg width="20" height="20" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1418 2.26937V0.0488281H0.124634V2.26937H20.1418ZM6.24316 20.0488H8.46405V7.82092H20.1436V5.60037H6.24316V20.0488ZM14.0117 20.0488H11.8123V11.1512H14.0117V20.0488ZM0.111267 7.82267H2.94278V5.60037H0.111267V7.82267Z"
      fill={color}
    />
  </svg>
)

export default Logo
