import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import { message, Dropdown, Row, Button } from 'antd'
import { USER } from '../../graphqls/queries'
import { UserAvatar } from '../../assets/icons'
import '../../styles/avatar.less'

const Index = () => {
  const navigate = useNavigate()
  const userId = localStorage.getItem('userId') || ''
  const isApp = JSON.parse(localStorage.getItem('isApp') || 'false')
  const { data: { user: { firstName = '', lastName = '', email = '' } = {} } = {} } = useQuery(USER, {
    variables: { id: userId },
    onCompleted({ user }) {
      const { firstName: aliasFirstName, lastName: aliasLastName, email: aliasEmail, phone } = user || {}
      localStorage.setItem('email', aliasEmail)
      localStorage.setItem('firstName', aliasFirstName)
      localStorage.setItem('lastName', aliasLastName)
      localStorage.setItem('phone', phone)
    },
  })

  const handleClick = () => {
    localStorage.clear()
    message.success('Sign out and sign in successfully', undefined, navigate('/login'))
  }

  const menu = (
    <Row className="header_menu">
      <div className="user_info text-primary">
        <p className="user_name">{[firstName, lastName].join(' ')}</p>
        <p className="user_email">{email}</p>
      </div>

      {!isApp ? (
        <>
          <Divider />
          <Button className="logout text-primary" onClick={handleClick}>
            Log out
          </Button>
        </>
      ) : (
        ''
      )}
    </Row>
  )
  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <div className="avatar">
        <UserAvatar />
      </div>
    </Dropdown>
  )
}

export default Index
