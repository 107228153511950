import { useLocation, Navigate } from 'react-router-dom'
import getQueryVariable from '../utils/getQueryVariable'

export default function Auth({ children }) {
  const { pathname } = useLocation()
  const { token, isApp } = getQueryVariable()
  const userId = localStorage.getItem('userId')
  const leaseId = localStorage.getItem('leaseId')

  if (token) {
    localStorage.setItem('token', token)
  }
  if (isApp) {
    localStorage.setItem('isApp', isApp)
  }
  const existedToken = localStorage.getItem('token')
  const existedIsApp = JSON.parse(localStorage.getItem('isApp') || 'false')

  const isLogin = userId && existedToken
  if (!existedIsApp) {
    if (!isLogin && pathname !== '/login') {
      return <Navigate to="/login" state={{ from: window.location.pathname }} replace />
    }

    if (isLogin && pathname === '/login') {
      return <Navigate to="/overview" replace />
    }

    if (isLogin && !leaseId) {
      return <Navigate to="/leaseSwitch" replace />
    }
  }
  return children
}
