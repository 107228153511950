import { useState } from 'react'
import { useQuery } from '@apollo/client'
import moment from 'moment'
import Statsig from 'statsig-js'
import { message } from 'antd'
import { upperCase } from 'lodash-es'
import { LEASE } from '../../../graphqls/queries'
import { LeaseType } from '../utils/types.d'
import userAgent from '../../../utils/getUserAgent'

const useLease = (callback: (params: LeaseType) => void) => {
  const leaseId = localStorage.getItem('leaseId')
  const [lease, setLease] = useState({})

  useQuery(LEASE, {
    variables: {
      leaseId,
    },
    onCompleted: ({ lease: leaseData = {} }) => {
      const { unit } = leaseData || {}
      const { address: unitAddress } = unit || {}
      const { area } = unitAddress
      Statsig.updateUser({
        statsigEnvironment: {
          tier: upperCase(window.STAGE),
        },
        userID: localStorage.getItem('userId') || '',
        userAgent: { ...userAgent } as any,
        ip: window.STATSIG_ENV_IP !== '__STATSIG_ENV_IP__' ? window.STATSIG_ENV_IP : '',
        custom: {
          area,
        },
      } as any)
      if (callback) {
        callback(leaseData)
      }

      const { unitId, leaseType, propertyId, endDate } = leaseData || {}
      setLease(leaseData)
      localStorage.setItem('unitId', unitId)
      localStorage.setItem('leaseType', leaseType)
      localStorage.setItem('propertyId', propertyId)
      localStorage.setItem('leaseExpired', JSON.stringify(moment(endDate).isBefore()))
    },
    onError(error) {
      message.error(error)
    },
    skip: !leaseId,
    errorPolicy: 'ignore',
  })

  return lease
}

export default useLease
