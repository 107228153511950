import { useNavigate, useLocation } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import { Layout, Row } from 'antd'
import { startCase } from 'lodash-es'
import { getHeaderTitle, getHeaderBg } from '../utils'
import { ChevronLeft, MWebLogo } from '../assets/icons'
import Avatar from './avatar/index'
import MenuButton from './menuButton'
import '../styles/header.less'

const menuHome = [
  'overview',
  'payment',
  'my-request',
  'my-inspections',
  'user-profile',
  'to-do',
  'all-explore',
  'contact-us',
  'customer-service',
  'insurance',
  'info-desk',
  'check-in-information',
  'set-up-your-utility',
  'pick-up-your-keys',
  'tripalink-move-in-tips',
  'get-ready-to-move-a-guide',
  'd1-eating-&-shopping',
  'explore-apartment',
  'payment-methods',
]

export default function Header() {
  const navigate = useNavigate()
  const location = useLocation()
  const isApp = JSON.parse(localStorage.getItem('isApp') || 'false')
  const isMenuHome = menuHome.includes(location.pathname.split('/').pop())

  const isBack = !location.pathname.includes('/successed')

  const onHome = () => navigate('/overview')
  const onBackRoute = () => navigate(-1)

  const onBackApp = () => {
    if (location.state) {
      const { paymentAmount = 0 } = location.state
      if (paymentAmount) {
        onBackRoute()
        return
      }
    }
    if (isMenuHome) {
      window.callFlutter()
    } else {
      onBackRoute()
    }
  }

  const backComponent = () => {
    if (isApp) {
      return (
        <IconButton onClick={onBackApp}>
          <ChevronLeft color={getHeaderBg() ? '#ffffff' : '#75798D'} />
        </IconButton>
      )
    }
    if (isMenuHome && (location.state === 1 || !location.state)) {
      return (
        <IconButton onClick={onHome}>
          <MWebLogo color={getHeaderBg() ? '#FFFFFF' : '#151515'} />
        </IconButton>
      )
    }
    if (isBack) {
      return (
        <IconButton onClick={onBackRoute}>
          <ChevronLeft />
        </IconButton>
      )
    }
    return <IconButton />
  }

  return (
    <Layout.Header id="header" className="">
      <Row className="mt_menu_content ">
        {backComponent()}
        {!getHeaderBg() && <p className="header_title">{startCase(getHeaderTitle())}</p>}
        {isApp ? <div className="w-4 h-4" /> : <MenuButton />}
      </Row>
      <Row className="pc_menu_content">
        <Avatar />
      </Row>
    </Layout.Header>
  )
}
