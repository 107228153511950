import { useState, useEffect } from 'react'
import { Outlet, useOutlet, Navigate } from 'react-router-dom'
import { StyledEngineProvider, createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline, Typography, Stack, Button, Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { Layout, Grid } from 'antd'
import { debounce } from 'lodash-es'
import SiderBar from './siderBar'
import Header from './header'
import LeaseDialog from './leaseDialog'
import useLease from './payment/hooks/useLease'
import useOwnLease from './payment/hooks/useOwnLease'
// import UseStatsigProvider from '../routes/statsigProvider'
import '../styles/layout.less'
import themeConfig from '../theme'
import { getHeaderBg, hasHeader } from '../utils'

const theme = createTheme(themeConfig)

const { useBreakpoint } = Grid

export default function LayOut() {
  const screens = useBreakpoint()
  const isPC = screens.md
  const outlet = useOutlet()
  const { Content } = Layout
  const [isScrolling, setScrolling] = useState(false)
  // the body  scrolled over the banner
  const [isScrollBanner, setScrollBanner] = useState(false)
  const isApp = JSON.parse(localStorage.getItem('isApp') || 'false')
  const token = localStorage.getItem('token')

  const [visible, setVisible] = useState(false)
  const [info, setInfo] = useState(null)
  const icon = `${import.meta.env.VITE_CDN_PREFIX}/tenant%2F0b1fec48a52a0ecb.png`
  const [leaseList, setLeaseList] = useState([])

  if (isApp && !token) {
    return (
      <div className="w-full h-screen flex flex-row justify-center items-center">
        <CircularProgress className="t-mui-loading" />
      </div>
    )
  }

  const scrollChange = () => {
    const { scrollTop } = document.documentElement || {}
    const { scrollHeight: bannerHeight = 0 } =
      document.querySelector('.overview_header') || document.querySelector('.mob_overview_header') || {}
    setScrolling(scrollTop > 0)
    setScrollBanner(scrollTop > bannerHeight)
  }
  const onScrollChange = debounce(scrollChange, 10)

  useEffect(() => {
    window.addEventListener('scroll', onScrollChange, { passive: true })
    return () => {
      window.removeEventListener('scroll', onScrollChange)
    }
  }, [])

  useLease(data => {
    const {
      property: { name, aliasName },
      bedroom,
      unit,
      leaseType,
    } = data || {}
    const { address } = bedroom || {}
    const { address: unitAddress } = unit || {}
    const { fullAddress } = address || {}
    const { fullAddress: unitFullAddress } = unitAddress
    if (aliasName || name) {
      setInfo({
        name,
        aliasName,
        fullAddress: leaseType === 'ENTIRE' ? unitFullAddress : fullAddress,
      })
    }
  })

  useOwnLease(data => {
    setLeaseList(data)
  })

  const onClose = () => {
    setVisible(false)
  }
  const onOpen = () => {
    setVisible(true)
  }

  if (!outlet) {
    return <Navigate replace to="/overview" />
  }

  const getHeaderComponent = () => {
    let component = ''
    if (hasHeader()) return component
    if (getHeaderBg()) {
      if (isPC) {
        component = (
          <>
            <div
              className={`transition-all duration-500 w-full top-0 right-0 sticky  z-10 ${isScrolling ? ' bg-white opacity-60' : ''} ${
                isScrollBanner ? ' opacity-100' : ''
              }`}
            >
              <Header />
            </div>

            <div className="overview_header mt-80- pt-16">
              {info ? (
                <Box className="px-16">
                  <Stack direction="column">
                    <Typography variant="p" className="font-averta text-white text-32	 leading-12 font-bold">
                      {info?.aliasName || info?.name}
                    </Typography>
                    <Typography variant="p" className="font-averta text-white text-base	 leading-6 font-normal">
                      {info?.fullAddress}
                    </Typography>
                  </Stack>
                  {leaseList.length > 1 ? (
                    <Button
                      sx={{
                        '&.MuiButton-root': {
                          backgroundColor: 'transparent',
                          '&.Mui-disabled': {
                            backgroundColor: 'transparent',
                          },
                        },
                      }}
                      variant="outlined"
                      className="border-white rounded-xl w-auto h-auto capitalize mt-4"
                      onClick={onOpen}
                    >
                      <Stack direction="row" justifyContent="center" alignItems="center" spacing="4px" className="py-1 px-3">
                        <img src={icon} alt="" className="scale-50" />
                        <Typography variant="p" className="font-averta text-white text-sm	leading-6 font-bold underline">
                          Switch Unit/Room
                        </Typography>
                      </Stack>
                    </Button>
                  ) : (
                    ''
                  )}
                </Box>
              ) : (
                ''
              )}
            </div>
          </>
        )
      } else {
        component = (
          <>
            {!isApp ? (
              <div
                className={`transition-all duration-500 w-full top-0 right-0 sticky  z-10 ${isScrolling ? ' bg-white opacity-60' : ''} ${
                  isScrollBanner ? ' opacity-100' : ''
                }`}
              >
                <Header />
              </div>
            ) : (
              ''
            )}
            <div className="mob_overview_header pt-16 mt-72-">
              {info ? (
                <Box className="px-4">
                  <Stack direction="column">
                    <Typography variant="p" className="font-averta text-white text-2xl leading-8 font-semibold">
                      {info?.aliasName || info?.name}
                    </Typography>
                    <Typography variant="p" className="font-averta text-white text-sm	 leading-6 font-normal">
                      {info?.fullAddress}
                    </Typography>
                  </Stack>
                  <Button
                    sx={{
                      '&.MuiButton-root': {
                        backgroundColor: 'transparent',
                        '&.Mui-disabled': {
                          backgroundColor: 'transparent',
                        },
                      },
                    }}
                    variant="outlined"
                    className="border-white rounded-xl w-auto h-auto capitalize mt-4"
                    onClick={onOpen}
                  >
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing="8px" className="py-1 px-3">
                      <img src={icon} alt="" className="w-2 h-3" />
                      <Typography variant="p" className="font-averta text-white text-xs	leading-6 font-bold underline">
                        Switch Unit/Room
                      </Typography>
                    </Stack>
                  </Button>
                </Box>
              ) : (
                ''
              )}
            </div>
          </>
        )
      }
    } else {
      component = (
        <div className="w-full top-0 sticky bg-white z-50">
          <Header />
        </div>
      )
    }
    return component
  }

  return (
    // <UseStatsigProvider waitForInitialization={false}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="layout p-0 m-0 flex flex-row">
          {isPC && !isApp && (
            <div className="flex-[0_0_300px]">
              <SiderBar />
            </div>
          )}

          <div className={`site-layout ${isPC ? 'pc-layout' : ''} relative flex-auto`}>
            {getHeaderComponent()}
            <Content className="p-0">
              <Outlet />
            </Content>
          </div>
        </div>
        <LeaseDialog visible={visible} onClose={onClose} />
      </ThemeProvider>
    </StyledEngineProvider>
    // </UseStatsigProvider>
  )
}
