import { ReactElement, useMemo, useState } from 'react'
import { useLocation, NavLink } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Box from '@mui/material/Box'
import type { navItem } from '../routes/navsLink'
import getNavsLinkConfig from '../routes/navsLink'
import useLease from './payment/hooks/useLease'
import { DownArrow } from '../assets/icons'

interface Props {
  onClose?: () => void
  children?: ReactElement | string | undefined
}

function MenuBar({ children, onClose }: Props) {
  const { pathname } = useLocation()
  const [expandMenu, setExpandMenu] = useState<string[]>([])
  const [disablePaymentAndMaintenance, setDisablePaymentAndMaintenance] = useState(true)

  useLease(({ unit }) => {
    const { address } = unit || {}
    const { area } = address || {}

    if (area === 'USC') {
      setDisablePaymentAndMaintenance(true)
    } else {
      setDisablePaymentAndMaintenance(false)
    }
  })

  const routeList = useMemo<Array<navItem>>(() => {
    let defaultLinks = []
    defaultLinks = getNavsLinkConfig()
    // if (disablePaymentAndMaintenance) {
    //   defaultLinks = defaultLinks.filter(link => !['Maintenance'].includes(link.name))
    // }
    return defaultLinks
  }, [disablePaymentAndMaintenance])

  const onChangeMenu = () => {
    if (onClose) {
      onClose()
    }
  }

  const onExpandMenu = ({
    currentTarget: {
      dataset: { name },
    },
  }: any) => {
    setExpandMenu(expandMenu.includes(name) ? expandMenu.filter(menuName => menuName !== name) : [...expandMenu, name])
  }

  const getMenuItems = (list: navItem[]) =>
    list.map(item => {
      if (item.children) {
        return (
          <MenuList key={item.path} className={`p-0 overflow-hidden ${expandMenu.includes(item.name) ? '' : 'h-[76px]'}`}>
            <MenuItem
              className={`${
                item.path.includes(pathname.split('/')[1]) ? 'text-red font-semibold' : 'text-secondary'
              } font-averta text-lg gap-5 px-10 py-5 hover:text-red hover:bg-gray-250`}
              sx={{
                '&.MuiMenuItem-root': {
                  ':hover': {
                    svg: {
                      path: {
                        fill: '#E74F4F',
                      },
                    },
                  },
                },
              }}
              onClick={onExpandMenu}
              data-name={item.name}
            >
              <Box component="span" className="w-9 h-9">
                {item.path.includes(pathname.split('/')[1]) ? item.meta?.activeIcon : item.meta?.icon}
              </Box>
              <Box component="span">{item.name}</Box>
              <Box className={`${expandMenu.includes(item.name) ? 'rotate-180' : ''}`}>
                <DownArrow color={expandMenu.includes(item.name) ? '#40444E' : '#858B98'} />
              </Box>
            </MenuItem>
            {getMenuItems(item.children)}
          </MenuList>
        )
      }
      return (
        <NavLink to={item.path} key={item.path}>
          <MenuItem
            disableRipple
            className={`${
              pathname.includes(item.path) ? 'bg-gray-250 text-red font-semibold' : 'text-secondary'
            } font-averta text-lg gap-5 px-10 py-5 hover:text-red hover:bg-gray-250`}
            data-path={item.path}
            onClick={onChangeMenu}
            sx={{
              '&.MuiMenuItem-root': {
                ':hover': {
                  svg: {
                    path: {
                      fill: '#E74F4F',
                    },
                  },
                },
              },
            }}
          >
            <Box component="span" className="w-9 h-9">
              {item.path.includes(pathname.split('/')[1]) ? item.meta?.activeIcon : item.meta?.icon}
            </Box>
            <Box component="span">{item.name}</Box>
          </MenuItem>
        </NavLink>
      )
    })

  return (
    <MenuList>
      {getMenuItems(routeList)} {children}
    </MenuList>
  )
}

export default MenuBar
