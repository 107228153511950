interface Props {
  color?: string
  width?: string
  height?: string
}

const Avatar = ({ color = '#CCD0E5', width = '40', height = '40' }: Props) => (
  <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_992_10232)">
      <circle cx="20" cy="20" r="20" fill="#EEF0F5" />
      <path
        d="M20.0002 22.3333C16.3168 22.3333 13.3335 19.35 13.3335 15.6667C13.3335 11.9833 16.3168 9 20.0002 9C23.6835 9 26.6668 11.9833 26.6668 15.6667C26.6668 19.35 23.6835 22.3333 20.0002 22.3333Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33447 35.4799C7.43437 29.307 12.4695 24.3337 18.6663 24.3337H21.3329C27.5297 24.3337 32.5649 29.307 32.6648 35.4799C29.2159 38.3051 24.8057 40.0003 19.9996 40.0003C15.1935 40.0003 10.7833 38.3051 7.33447 35.4799Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_992_10232">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Avatar
