import React from 'react'

interface Props {
  color?: string
}

const Profile = ({ color = '#A6A9BB' }: Props) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 30C24.6274 30 30 24.6274 30 18C30 11.3726 24.6274 6 18 6C11.3726 6 6 11.3726 6 18C6 24.6274 11.3726 30 18 30ZM21 13C21 14.6569 19.6569 16 18 16C16.3431 16 15 14.6569 15 13C15 11.3431 16.3431 10 18 10C19.6569 10 21 11.3431 21 13ZM24 22C24 24.2091 21.3137 26 18 26C14.6863 26 12 24.2091 12 22C12 19.7909 14.6863 18 18 18C21.3137 18 24 19.7909 24 22Z"
      fill={color}
    />
  </svg>
)

export default Profile
