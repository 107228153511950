import { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { Menu } from '../assets/icons'
import { getHeaderBg } from '../utils'
import MobileSiderBar from './mobileSiderBar'

const MenuButton = () => {
  const [menuStatus, setMenuStatus] = useState(false)
  const onOpenMenu = () => setMenuStatus(true)
  const onCloseMenu = () => {
    setMenuStatus(false)
  }

  return (
    <>
      <IconButton onClick={onOpenMenu}>
        <Menu color={getHeaderBg() ? '#ffffff' : '#050A22'} />
      </IconButton>
      <MobileSiderBar onClose={onCloseMenu} open={menuStatus} />
    </>
  )
}

export default MenuButton
