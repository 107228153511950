import { pick } from 'lodash-es'

const userAgent = window.navigator
  ? pick(window.navigator, [
      'vendorSub',
      'productSub',
      'vendor',
      'maxTouchPoints',
      'doNotTrack',
      'pdfViewerEnabled',
      'hardwareConcurrency',
      'cookieEnabled',
      'appCodeName',
      'appName',
      'appVersion',
      'platform',
      'product',
      'userAgent',
      'language',
      'onLine',
      'webdriver',
      'deviceMemory',
    ])
  : {}

export default userAgent
