import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import Drawer from '@mui/material/Drawer'
import { Box } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import MenuBar from './menuBar'
import { Signout } from '../assets/icons'
import '../styles/mobileSiderBar.less'

interface Props {
  open: boolean
  onClose: () => void
}

const MobileSiderBar = ({ open, onClose }: Props) => {
  const navigate = useNavigate()
  const isApp = JSON.parse(localStorage.getItem('isApp') || 'false')
  const handleClick = () => {
    localStorage.clear()
    message.success('Sign out and sign in successfully', undefined, navigate('/login'))
  }

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={open}
      sx={{
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 312 },
      }}
    >
      <Box className="mobsider-action h-18">
        <IconButton onClick={onClose}>
          <div className="close_menu_icon" />
        </IconButton>
      </Box>
      <MenuBar onClose={onClose}>
        {!isApp ? (
          <button
            className="flex gap-5 items-center pl-10 py-5 mt-32 hover:bg-gray-250 w-full text-secondary font-averta text-lg hover:text-red"
            type="button"
            onClick={handleClick}
          >
            <Signout />
            <span className="">Log out</span>
          </button>
        ) : (
          ''
        )}
      </MenuBar>
    </Drawer>
  )
}

export default MobileSiderBar
