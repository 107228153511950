import { Layout } from 'antd'
import MenuBar from './menuBar'
import { PClogo } from '../assets/icons'
import '../styles/sider.less'

const { Sider } = Layout

const Nav = () => (
  <Sider className="sider" theme="light">
    <div className="logo">
      <PClogo />
    </div>
    <MenuBar />
  </Sider>
)
export default Nav
